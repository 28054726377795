import React from 'react';

import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import { EmploymentStatus, HideOnScroll, Link } from '../';
import {
  LocationPathType,
  PageMetaData,
  PageName,
  SectionName,
} from '../../enums';
import {
  NavigationComponentDataType,
  RedirectProps,
  SelectedRouteNavigationDataType,
} from '../../types';
import {
  CenterColumn,
  LeftColumn,
  RightColumn,
  Root,
  StyledAppBar,
} from './Navigation.styles';

export type Props = {
  data?: NavigationComponentDataType;
  redirect: (params: RedirectProps) => void;
  scrollToSection: ({ section }: { section: SectionName }) => void;
  selectedRouteNavigationData: SelectedRouteNavigationDataType;
};

export const Navigation = ({
  data,
  redirect,
  scrollToSection,
  selectedRouteNavigationData,
}: Props) => {
  const { isOpenToWork, navigationTitle } = data || {};
  const isHomePage =
    selectedRouteNavigationData.path === PageMetaData[PageName.HOME].path;
  const shouldDisplayContact = [
    PageMetaData[PageName.HOME].path,
    PageMetaData[PageName.WRITING].path,
    ...(PageMetaData[PageName.HOME]?.sections as LocationPathType[]),
  ].includes(selectedRouteNavigationData.path);
  return (
    <HideOnScroll>
      <StyledAppBar elevation={0}>
        <Root>
          <Toolbar>
            <LeftColumn>
              <Link href={PageMetaData[PageName.HOME].path}>
                {navigationTitle}
              </Link>
            </LeftColumn>
            {isOpenToWork && (
              <CenterColumn>
                <EmploymentStatus />
              </CenterColumn>
            )}
            <RightColumn>
              <Button
                onClick={() =>
                  isHomePage
                    ? scrollToSection({ section: SectionName.ABOUT })
                    : redirect({
                        href: PageMetaData[PageName.HOME].path,
                        section: SectionName.ABOUT,
                      })
                }
                variant="text">
                {SectionName.ABOUT}
              </Button>
              <Button
                onClick={() =>
                  isHomePage
                    ? scrollToSection({ section: SectionName.EXPERIENCE })
                    : redirect({
                        href: PageMetaData[PageName.HOME].path,
                        section: SectionName.EXPERIENCE,
                      })
                }
                variant="text">
                {SectionName.EXPERIENCE}
              </Button>
              {shouldDisplayContact && (
                <Button
                  onClick={() =>
                    scrollToSection({ section: SectionName.CONTACT })
                  }
                  variant="text">
                  {SectionName.CONTACT}
                </Button>
              )}
            </RightColumn>
          </Toolbar>
        </Root>
      </StyledAppBar>
    </HideOnScroll>
  );
};

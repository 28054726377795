import React, { ReactElement } from 'react';

import { Root, Content } from './Accordion.styles';

type Props = {
  isCollapsed?: boolean;
  children: ReactElement;
};

export const Accordion = ({ children, isCollapsed = true }: Props) => {
  return (
    <Root isCollapsed={isCollapsed}>
      <Content isCollapsed={isCollapsed}>{children}</Content>
    </Root>
  );
};

import React, { useEffect } from 'react';

import {
  AlertBar,
  ConfirmationDialog,
  Footer,
  Navigation,
  PageLoader,
} from '../../components';
import {
  GlobalComponent,
  LocationPathType,
  PageMetaData,
  PageName,
} from '../../enums';
import { usePageContentData } from '../../hooks';
import { PageContent } from '../PageContent';
import { Root } from './MainView.styles';

export const MainView = () => {
  const {
    data,
    dialogData,
    globalComponentData,
    onUpdate,
    pageTitle,
    redirect,
    scrollToSection,
    sectionKeyToHTMLDivElementRef,
    selectedRouteNavigationData,
    setPageMetaTitle,
    setSnackbarData,
    shouldDisplayPageLoader,
    snackbarData,
  } = usePageContentData();

  const { dialogTitle, isDialogOpen, onCloseDialog, onConfirmDialog } =
    dialogData || {};

  const shouldDisplayFooter = [
    PageMetaData[PageName.HOME].path,
    PageMetaData[PageName.WRITING].path,
    ...(PageMetaData[PageName.HOME]?.sections as LocationPathType[]),
  ].includes(selectedRouteNavigationData.path);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPageMetaTitle(), [window.location.pathname]);

  return (
    <Root>
      {snackbarData && (
        <AlertBar
          onClose={() => setSnackbarData(null)}
          severity={snackbarData?.severity ?? 'success'}
          text={snackbarData.text ?? ''}
        />
      )}
      {shouldDisplayPageLoader ? (
        <PageLoader loadingMessage="Greetings" />
      ) : (
        <>
          <Navigation
            redirect={redirect}
            scrollToSection={scrollToSection}
            selectedRouteNavigationData={selectedRouteNavigationData}
            data={globalComponentData?.[GlobalComponent.NAVIGATION]}
          />
          <PageContent
            data={data}
            onUpdate={onUpdate}
            pageTitle={pageTitle}
            scrollToSection={scrollToSection}
            sectionKeyToHTMLDivElementRef={sectionKeyToHTMLDivElementRef}
            selectedRouteNavigationData={selectedRouteNavigationData}
          />
          {shouldDisplayFooter && (
            <Footer
              data={globalComponentData?.[GlobalComponent.FOOTER]}
              ref={sectionKeyToHTMLDivElementRef.CONTACT}
            />
          )}
        </>
      )}
      {isDialogOpen && (
        <ConfirmationDialog
          dialogTitle={dialogTitle ?? ''}
          isDialogOpen={isDialogOpen}
          onCloseDialog={onCloseDialog}
          onConfirmDialog={onConfirmDialog}
        />
      )}
    </Root>
  );
};

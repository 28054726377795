import React from 'react';

type Props = {
  size?: 'small' | 'large';
};

export const EmploymentStatus = ({ size = 'small' }: Props) => {
  const text = 'open to new opportunities';
  return size === 'small' ? <span>{text}</span> : <h4>{text}</h4>;
};

import React, { forwardRef } from 'react';

import { Anchor } from '../../../components';
import { AboutType } from '../../../types';
import {
  Headline,
  CenterGridColumn,
  LeftGridColumn,
  RightGridColumn,
  Root,
  SkillGrid,
  SkillList,
  Subheading,
} from './About.styles';

type Props = {
  data?: AboutType;
};

type SkillListDataType = {
  leftColumn: string[];
  rightColumn: string[];
};

export const About = forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
  const { headline, subheading, coreSkills } = data || {};
  const skillListData = coreSkills?.reduce(
    (output, skill, idx) => {
      if (idx < 4) {
        output.leftColumn.push(skill);
      } else {
        output.rightColumn.push(skill);
      }
      return output;
    },
    { leftColumn: [], rightColumn: [] } as SkillListDataType,
  );
  return (
    <Root>
      <Anchor ref={ref} />
      <div>
        {headline && <Headline>{headline}</Headline>}
        {subheading && <Subheading>{subheading}</Subheading>}
        <SkillGrid>
          <LeftGridColumn>What I do:</LeftGridColumn>
          <CenterGridColumn>
            <SkillList>
              {skillListData?.leftColumn.map((skill) => (
                <li key={skill}>{skill}</li>
              ))}
            </SkillList>
          </CenterGridColumn>
          <RightGridColumn>
            <SkillList>
              {skillListData?.rightColumn.map((skill) => (
                <li key={skill}>{skill}</li>
              ))}
            </SkillList>
          </RightGridColumn>
        </SkillGrid>
      </div>
    </Root>
  );
});

About.displayName = 'About';

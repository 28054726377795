import { userFragment } from '../fragments';

export const getUserById = /* GraphQL */ `
  query getUserById($userId: ID!) {
    getUserById(userId: $userId) {
      ...userFragment
    }
  }
  ${userFragment}
`;

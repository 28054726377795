import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/system';

export const StyledAppBar = styled(AppBar)({
  transitionDuration: '0.5s',
  transitionTimingFunction: 'ease',
  transitionProperty: 'background-color, color',
});

export const Root = styled('div')({
  maxWidth: 1200,
  margin: '0 auto',
  width: '100%',
});

export const LeftColumn = styled('div')({
  flex: 1,
  '& a': {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 1,
  },
});

export const CenterColumn = styled('div')(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const RightColumn = styled('nav')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
  '& button': {
    fontSize: 16,

    '&:last-of-type': {
      paddingRight: 0,
    },

    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        display: 'none',
      },
    },
  },
}));

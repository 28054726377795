/* eslint-disable security/detect-object-injection */
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';

import {
  LocationPathType,
  PageMetaData,
  PageName,
  SectionName,
} from '../enums';
import {
  RedirectProps,
  SectionKeyToHTMLDivElementRefType,
  SelectedRouteNavigationDataType,
} from '../types';

type Props = {
  isPageLoading?: boolean;
};

type ReturnType = {
  redirect: (params: RedirectProps) => void;
  scrollToSection: ({ section }: { section: SectionName }) => void;
  sectionKeyToHTMLDivElementRef: SectionKeyToHTMLDivElementRefType;
  selectedRouteNavigationData: SelectedRouteNavigationDataType;
};

export const useRouteData = ({ isPageLoading }: Props): ReturnType => {
  const path = window.location.pathname.toLowerCase() as LocationPathType;
  const [selectedRouteNavigationData, setSelectedRouteNavigationData] =
    useState<SelectedRouteNavigationDataType>({
      path: path ?? PageMetaData[PageName.HOME].path,
    });

  const sectionKeyToHTMLDivElementRef = useMemo(
    () =>
      Object.keys(SectionName).reduce((sections, section) => {
        return { ...sections, [section]: createRef<HTMLDivElement>() };
      }, {} as SectionKeyToHTMLDivElementRefType),
    [],
  );

  const scrollToSection = useCallback(
    ({ section }: { section: SectionName }) => {
      window.history.pushState(null, '', `/${section.toLowerCase()}`);
      sectionKeyToHTMLDivElementRef[section]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    },
    [sectionKeyToHTMLDivElementRef],
  );

  const setHomePageSectionByPath = useCallback(
    ({ section }: { section?: SectionName }) => {
      if (
        section ||
        PageMetaData[PageName.HOME].sections?.includes(
          selectedRouteNavigationData.path,
        )
      ) {
        const selectedSection =
          section ||
          (selectedRouteNavigationData.path
            .substring(1)
            .toUpperCase() as SectionName);
        setTimeout(() => {
          if (sectionKeyToHTMLDivElementRef[selectedSection]?.current) {
            scrollToSection({ section: selectedSection });
          }
        }, 500);
      }
    },
    [
      scrollToSection,
      sectionKeyToHTMLDivElementRef,
      selectedRouteNavigationData.path,
    ],
  );

  const redirect = useCallback(
    ({ href, section }: RedirectProps) => {
      window.history.pushState(null, '', href);
      const navigationEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navigationEvent);
      if (section) {
        setTimeout(() => {
          window.history.pushState(null, '', `/${section.toLowerCase()}`);
          setHomePageSectionByPath({ section });
        }, 500);
      }
    },
    [setHomePageSectionByPath],
  );

  useEffect(() => {
    if (!isPageLoading) {
      setHomePageSectionByPath({});
    }
  }, [setHomePageSectionByPath, isPageLoading]);

  useEffect(() => {
    const onLocationChange = () => {
      const path = window.location.pathname.toLowerCase() as LocationPathType;
      setSelectedRouteNavigationData({ path });
    };
    window.addEventListener('popstate', onLocationChange);
    return () => {
      window.removeEventListener('popstate', onLocationChange);
    };
  }, []);

  return {
    redirect,
    sectionKeyToHTMLDivElementRef,
    selectedRouteNavigationData,
    scrollToSection,
  };
};

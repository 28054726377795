import React, { forwardRef } from 'react';

import { Anchor, ExperienceCard } from '../../../components';
import { ExperienceType } from '../../../types';
import { Root } from './Experience.styles';

type Props = {
  data?: ExperienceType[];
};

export const Experience = forwardRef<HTMLDivElement, Props>(({ data }, ref) => (
  <Root>
    <Anchor ref={ref} verticalOffset={-65} />
    {data
      ?.sort((a, b) => b.order - a.order)
      ?.map((data) => (
        <ExperienceCard key={data.order} data={data} />
      ))}
  </Root>
));

Experience.displayName = 'Experience';

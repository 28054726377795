import React, { useContext } from 'react';

import { ColorMode, ThemeContext } from '../../theme';
import {
  ColorModeIndicator,
  Label,
  Root,
  Text,
  ThemePaletteIcon,
  ThemePaletteIconBackgroundColor,
  ThemePaletteIconList,
  ThemePaletteIconListItem,
} from './ColorModeSelect.styles';

export const ColorModeSelect = () => {
  const { colorMode, setColorMode } = useContext(ThemeContext);
  return (
    <Root colorMode={colorMode ?? ColorMode.LIGHT}>
      <Label>
        <ColorModeIndicator colorMode={colorMode ?? ColorMode.LIGHT} />
        <Text>{colorMode} MODE</Text>
      </Label>
      <ThemePaletteIconList>
        {Object.values(ColorMode)?.map((mode) => (
          <ThemePaletteIconListItem
            key={mode}
            isDisabled={mode === colorMode}
            onClick={() => setColorMode && setColorMode({ mode })}>
            <ThemePaletteIcon colorMode={mode}>
              <ThemePaletteIconBackgroundColor colorMode={mode} />
            </ThemePaletteIcon>
          </ThemePaletteIconListItem>
        ))}
      </ThemePaletteIconList>
    </Root>
  );
};

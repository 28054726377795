import {
  createPostMutation,
  deletePostMutation,
  updatePostMutation,
} from '../graphql/mutations';
import {
  CreatePostMutationInputType,
  CreatePostMutationResponseType,
  DeletePostMutationInputType,
  DeletePostMutationResponseType,
  UpdatePostMutationInputType,
  UpdatePostMutationResponseType,
} from '../types';
import { useMutation } from './useMutation';

type ReturnType = {
  createPost: (
    variables: CreatePostMutationInputType,
  ) => Promise<CreatePostMutationResponseType | undefined>;
  deletePost: (
    variables: DeletePostMutationInputType,
  ) => Promise<DeletePostMutationResponseType | undefined>;
  updatePost: (
    variables: UpdatePostMutationInputType,
  ) => Promise<UpdatePostMutationResponseType | undefined>;
  errorMessage?: string;
  hasError: boolean;
  isLoading: boolean;
};

export const usePostMutations = (): ReturnType => {
  const {
    isLoading: createPostIsLoading,
    error: createPostHasError,
    errorMessage: createPostErrorMessage,
    runMutation: createPost,
  } = useMutation<CreatePostMutationResponseType>({
    mutation: createPostMutation,
  });

  const {
    isLoading: deletePostIsLoading,
    error: deletePostHasError,
    errorMessage: deletePostErrorMessage,
    runMutation: deletePost,
  } = useMutation<DeletePostMutationResponseType>({
    mutation: deletePostMutation,
  });

  const {
    isLoading: updatePostIsLoading,
    error: updatePostHasError,
    errorMessage: updatePostErrorMessage,
    runMutation: updatePost,
  } = useMutation<UpdatePostMutationResponseType>({
    mutation: updatePostMutation,
  });

  return {
    createPost,
    deletePost,
    updatePost,
    errorMessage:
      createPostErrorMessage ||
      deletePostErrorMessage ||
      updatePostErrorMessage,
    hasError: createPostHasError || deletePostHasError || updatePostHasError,
    isLoading:
      createPostIsLoading || deletePostIsLoading || updatePostIsLoading,
  };
};

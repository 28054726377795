import React from 'react';

import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';

import { useBoolean } from '../../hooks';

type Variant = 'standard' | 'filled' | 'outlined';

export type AlertBarDataType = {
  severity?: AlertColor;
  text: string;
};

type Props = AlertBarDataType & {
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number;
  onClose?: () => void;
  open?: boolean;
  variant?: Variant;
};

export const AlertBar = ({
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration = 4000,
  onClose,
  open = true,
  severity = 'success',
  text,
  variant = 'filled',
}: Props) => {
  const { value: shouldDisplaySnackbar, setFalse: hideSnackbar } =
    useBoolean(open);
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      open={shouldDisplaySnackbar}
      onClose={() => {
        hideSnackbar();
        onClose && onClose();
      }}>
      <Alert
        sx={{ width: '900px', justifyContent: 'center' }}
        severity={severity}
        variant={variant}>
        {text}
      </Alert>
    </Snackbar>
  );
};

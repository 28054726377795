export enum UserAction {
  REMOVE_IMAGE = 'REMOVE_IMAGE',
  UPDATE = 'UPDATE',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
}

export const getUserDialogTitle = ({ action }: { action: UserAction }) => {
  switch (action) {
    case UserAction.UPDATE:
      return 'Are you sure you want to update this profile?';
    case UserAction.REMOVE_IMAGE:
      return 'Are you sure you want to delete this image?';
    case UserAction.UPLOAD_IMAGE:
      return 'Are you sure you want to update this image?';
    default: {
      const exhaustiveCase: never = action;
      throw new Error(`Unhandled getUserDialogTitle case: ${exhaustiveCase}`);
    }
  }
};

/* eslint-disable security/detect-object-injection */
import { alpha } from '@mui/material';

import { ColorMode, OwnerStateType, THEME_PALETTE } from '.';

type ColorModeValues = `${ColorMode}`;

export const useThemeOptions = () => {
  const getDesignTokens = ({
    mode = ColorMode.LIGHT,
  }: {
    mode: ColorModeValues;
  }) => ({
    palette: {
      primary: {
        main: THEME_PALETTE[mode].background,
      },
      text: {
        primary: THEME_PALETTE[mode].text,
      },
      background: {
        default: THEME_PALETTE[mode].background,
      },
    },
    typography: {
      fontFamily: 'Montserrat Variable, sans-serif',
    },
    breakpoints: {
      values: {
        xxs: 0,
        xs: 300,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '.85rem',
            fontWeight: 300,
            transitionDuration: '0.5s',
            transitionTimingFunction: 'ease',
            transitionProperty: 'background-color, border-color, color, fill',
          },
          'svg, a, a:visited': {
            color: THEME_PALETTE[mode].text,
          },
          'h1, h2, h3, h4': {
            fontFamily: 'Battambang, serif',
            lineHeight: 1.2,
          },
          'h2, h3, h4': {
            fontWeight: 300,
          },
          h1: {
            fontSize: '3.5rem',
          },
          h2: {
            fontSize: '2.3rem',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: OwnerStateType }) => ({
            letterSpacing: 1,
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                backgroundColor: THEME_PALETTE[mode].background,
                borderColor: alpha(THEME_PALETTE[mode].text, 0.5),
                color: THEME_PALETTE[mode].text,
                '&:hover': {
                  borderColor: THEME_PALETTE[mode].text,
                },
              }),
            ...(ownerState.variant === 'text' && {
              color: THEME_PALETTE[mode].text,
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: THEME_PALETTE[mode].text,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: THEME_PALETTE[mode].text,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: THEME_PALETTE[mode].background,
          },
        },
      },
    },
  });

  return { getDesignTokens };
};

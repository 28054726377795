import React, { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Amplify } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';
import CssBaseline from '@mui/material/CssBaseline';

import config from './aws-config';
import { MainView } from './containers';
import { ThemeContextProvider } from './theme';

Amplify.configure(config);

function App() {
  return (
    <StrictMode>
      <Router>
        <Authenticator.Provider>
          <ThemeContextProvider>
            <CssBaseline />
            <MainView />
          </ThemeContextProvider>
        </Authenticator.Provider>
      </Router>
    </StrictMode>
  );
}

export default App;

import { useCallback, useEffect, useMemo, useState } from 'react';

import { usePostMutations } from '.';
import { AlertBarDataType, DialogDataType } from '../components';
import { PostAction, getPostDialogTitle } from '../enums';
import {
  CreatePostMutationInputType,
  DeletePostMutationInputType,
  PostActionHandlerInputType,
  PostType,
  UpdatePostMutationInputType,
} from '../types';

type Props = {
  data?: PostType[];
  isDataFetching: boolean;
  setDialogData: (variables: DialogDataType | null) => void;
  setSnackbarData: (variables: AlertBarDataType | null) => void;
};

type ReturnType = {
  data: {
    isLoading: boolean;
    isEditMode: boolean;
    postList?: PostType[];
    selectedPost: PostType;
  };
  dispatchAction: ({ action, data }: PostActionHandlerInputType) => void;
};

export const usePostData = ({
  data,
  isDataFetching,
  setDialogData,
  setSnackbarData,
}: Props): ReturnType => {
  const DEFAULT_POST = useMemo(
    () => ({
      category: '',
      content: '',
      id: '',
      title: '',
    }),
    [],
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [postList, setPostList] = useState<PostType[]>([]);
  const [selectedPost, setSelectedPost] = useState<PostType>(DEFAULT_POST);

  const {
    createPost,
    deletePost,
    updatePost,
    errorMessage: postMutationErrorMessage,
    hasError: postMutationHasError,
    isLoading: isPostMutationLoading,
  } = usePostMutations();

  useEffect(() => {
    if (data && data.length && !isDataFetching) {
      setPostList(data ?? ([] as PostType[]));
    }
  }, [data, isDataFetching]);

  const onDiscardPost = useCallback(() => {
    setSelectedPost(DEFAULT_POST);
    setIsEditMode(false);
  }, [DEFAULT_POST]);

  const onCreatePost = useCallback(
    async ({ input }: { input: CreatePostMutationInputType }) => {
      const response = await createPost(input);
      if (response?.data?.post) {
        setSelectedPost(DEFAULT_POST);
        setSnackbarData({
          severity: 'success',
          text: 'Post created successfully',
        });
        setPostList([...postList, response?.data?.post]);
      } else {
        setSnackbarData({
          severity: 'error',
          text: 'Error creating post.',
        });
      }
      return response;
    },
    [DEFAULT_POST, createPost, postList, setSnackbarData],
  );

  const onDeletePost = useCallback(
    async ({ input }: { input: DeletePostMutationInputType }) => {
      const response = await deletePost(input);
      const { post: postId } = response?.data || {};
      if (postId) {
        setSelectedPost(DEFAULT_POST);
        setIsEditMode(false);
        setSnackbarData({
          severity: 'success',
          text: 'Post deleted successfully',
        });
        const updatedPostList = postList.filter((post) => post.id !== postId);
        setPostList(updatedPostList);
      } else {
        setSnackbarData({
          severity: 'error',
          text: 'Error deleting post.',
        });
      }
      return response;
    },
    [DEFAULT_POST, deletePost, postList, setSnackbarData],
  );

  const onUpdatePost = useCallback(
    async ({ input }: { input: UpdatePostMutationInputType }) => {
      const response = await updatePost(input);
      const { post: updatedPost } = response?.data || {};
      if (updatedPost) {
        setSelectedPost(DEFAULT_POST);
        setIsEditMode(false);
        setSnackbarData({
          severity: 'success',
          text: 'Post updated successfully',
        });
        const updatedPostList = [...postList].map((post) => {
          if (post.id === updatedPost.id) {
            return updatedPost;
          }
          return post;
        });
        setPostList(updatedPostList);
      } else {
        setSnackbarData({
          severity: 'error',
          text: 'Error updating post.',
        });
      }
      return response;
    },
    [DEFAULT_POST, postList, setSnackbarData, updatePost],
  );

  const dispatchAction = useCallback(
    ({ action, data, shouldConfirm = false }: PostActionHandlerInputType) => {
      const executeAction = async () => {
        switch (action) {
          case PostAction.DELETE:
            if (data) {
              onDeletePost({ input: { postId: data?.id } });
            }
            return;
          case PostAction.DISCARD:
            return onDiscardPost();
          case PostAction.ON_CHANGE:
            if (data) {
              setSelectedPost(data);
            }
            return;
          case PostAction.PUBLISH:
            if (data) {
              const { category, title, content } = data;
              onCreatePost({
                input: { post: { category, title, content } },
              });
            }
            return;
          case PostAction.UPDATE:
            if (data) {
              onUpdatePost({ input: { post: data } });
              setIsEditMode(false);
            }
            return;
          case PostAction.SELECT:
            if (data) {
              setIsEditMode(true);
              setSelectedPost(data);
            }
            return;
          default: {
            const exhaustiveCase: never = action;
            throw new Error(`Unhandled PostAction case: ${exhaustiveCase}`);
          }
        }
      };

      if (shouldConfirm) {
        setDialogData({
          dialogTitle: getPostDialogTitle({ action }),
          isDialogOpen: true,
          onCloseDialog: () => setDialogData(null),
          onConfirmDialog: () => {
            executeAction();
            setDialogData(null);
          },
        });
      } else {
        executeAction();
      }
    },
    [onCreatePost, onDeletePost, onDiscardPost, onUpdatePost, setDialogData],
  );

  useEffect(() => {
    if (postMutationHasError) {
      setSnackbarData({
        severity: 'error',
        text:
          postMutationErrorMessage ??
          `An error occured: ${postMutationErrorMessage}`,
      });
    }
  }, [postMutationHasError, postMutationErrorMessage, setSnackbarData]);

  return {
    data: {
      isEditMode,
      isLoading: isPostMutationLoading || isDataFetching,
      postList,
      selectedPost,
    },
    dispatchAction,
  };
};

import React from 'react';
import ReactMarkdown from 'react-markdown';

import CircularProgress from '@mui/material/CircularProgress';

import { PostType, WritingPageDataType } from '../../types';

type Props = {
  data?: WritingPageDataType;
  pageTitle?: string;
};

export const Writing = ({ data, pageTitle }: Props) => {
  const { isLoading } = data ?? {};
  return (
    <>
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: 20 }}>
        <h1>{pageTitle}</h1>
        {isLoading && <CircularProgress size={20} />}
      </div>
      {data?.postList?.map((post: PostType) => {
        return (
          <div
            key={post.id}
            style={{ paddingBottom: 20, borderBottom: '1px solid #000' }}>
            <h2>{post.title}</h2>
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </div>
        );
      })}
    </>
  );
};

import React, { useContext } from 'react';

import { ColorMode, ThemeContext } from '../../theme';
import { Heading, Letter, Root } from './PageLoader.styles';

type Props = {
  loadingMessage: string;
};

export const PageLoader = ({ loadingMessage }: Props) => {
  const { colorMode } = useContext(ThemeContext);
  return (
    <Root colorMode={colorMode ?? ColorMode.LIGHT}>
      <Heading colorMode={colorMode ?? ColorMode.LIGHT}>
        {loadingMessage.split('').map((letter, idx) => (
          <Letter animationDelay={`${idx + 1}00ms`} key={`${Math.random()}`}>
            {letter}
          </Letter>
        ))}
      </Heading>
    </Root>
  );
};

import React, { ChangeEvent } from 'react';
import MdEditor from 'react-markdown-editor-lite';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MarkdownIt from 'markdown-it';

import { PageName, PostAction } from '../../enums';
import {
  ActionHandlerInputType,
  AdminPostManagerPageDataType,
  PostType,
} from '../../types';

type Props = {
  data?: AdminPostManagerPageDataType;
  onUpdate: (variables: ActionHandlerInputType) => void;
  pageTitle?: string;
};

export const PostManager = ({ data, onUpdate, pageTitle }: Props) => {
  const mdParser = new MarkdownIt();
  const { isEditMode, isLoading, postList, selectedPost } = data ?? {};
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedPost) {
      onUpdate({
        action: PostAction.ON_CHANGE,
        page: PageName.ADMIN_POST_MANAGER,
        data: {
          ...selectedPost,
          [event?.target?.name]: event?.target?.value,
        },
      });
    }
  };

  const onEditorChange = ({ text }: { text: string }) => {
    if (selectedPost) {
      onUpdate({
        action: PostAction.ON_CHANGE,
        page: PageName.ADMIN_POST_MANAGER,
        data: { ...selectedPost, content: text },
      });
    }
  };

  return (
    <>
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: 20 }}>
        <h1>{pageTitle}</h1>
        {isLoading && <CircularProgress size={20} />}
      </div>
      <div style={{ marginBottom: 20 }}>
        {postList?.map((post: PostType) => (
          <div key={post.id} style={{ borderBottom: '1px solid #000' }}>
            <Box sx={{ '& button': { m: 1 } }}>
              <span>{post.title}</span>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  onUpdate({
                    action: PostAction.SELECT,
                    page: PageName.ADMIN_POST_MANAGER,
                    data: post,
                  })
                }>
                View
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  onUpdate({
                    action: PostAction.DELETE,
                    page: PageName.ADMIN_POST_MANAGER,
                    data: post,
                    shouldConfirm: true,
                  })
                }>
                Delete
              </Button>
            </Box>
          </div>
        ))}
      </div>
      <Box component="form" noValidate autoComplete="off">
        <div style={{ display: 'inline-flex', gap: 20, marginBottom: 20 }}>
          <TextField
            id="title"
            label="Title"
            name="title"
            onChange={onChange}
            variant="outlined"
            value={selectedPost?.title}
          />
          <TextField
            id="category"
            label="Category"
            name="category"
            onChange={onChange}
            variant="outlined"
            value={selectedPost?.category}
          />
          {isEditMode && <strong>Edit mode</strong>}
        </div>
        <div>
          <MdEditor
            onChange={onEditorChange}
            renderHTML={(text) => mdParser.render(text)}
            style={{ height: '500px' }}
            value={selectedPost?.content}
          />
        </div>
        <div style={{ display: 'inline-flex', gap: 20, margin: '20px 0' }}>
          <Button
            disabled={
              !selectedPost?.category ||
              !selectedPost?.title ||
              !selectedPost?.content
            }
            variant="outlined"
            onClick={() =>
              onUpdate({
                action: isEditMode ? PostAction.UPDATE : PostAction.PUBLISH,
                page: PageName.ADMIN_POST_MANAGER,
                data: selectedPost,
                shouldConfirm: true,
              })
            }>
            {isEditMode ? 'Update' : 'Publish'}
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              onUpdate({
                action: PostAction.DISCARD,
                page: PageName.ADMIN_POST_MANAGER,
                shouldConfirm: true,
              })
            }
            color="info">
            Cancel
          </Button>
        </div>
      </Box>
    </>
  );
};

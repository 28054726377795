import React from 'react';

import { IconPropsType } from '..';

export const TEKSystemsLogo = ({ width, fill }: IconPropsType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={width || 800}
    fill={fill || 'currentColor'}>
    <path d="M76.2626 283.7622c-6.6412-5.9716-13.629-11.1566-19.2859-17.5239-11.7394-13.2135-22.0452-27.4997-26.3896-45.1107-4.3215-17.518.3858-32.8625 11.4364-46.6844 10.9387-13.6819 26.3591-18.8366 42.6098-21.2365 15.568-2.299 31.3691-3.3019 47.107-4.0913 16.9003-.8477 33.8486-1.1284 50.7716-1.0133 15.0142.1022 30.0224 1.086 45.4126 1.6957-3.5097 9.949-6.8195 19.3468-10.1402 28.7408-7.67 21.6978-10.3533 44.1746-8.6054 66.9444 1.6534 21.5387 7.7692 42.1727 16.6189 61.8939 7.9978 17.8228 15.6458 35.8462 24.5965 53.1883 16.0705 31.1371 33.4626 61.635 55.2723 89.1442 9.6402 12.1595 21.299 22.9195 33.0939 33.0946 7.1085 6.1324 16.6055 8.3907 26.3206 9.3974 8.007.8296 15.4987-.4246 23.1073-2.1468.7328-.1659 1.5502.042 3.1241.1117-12.984 10.007-26.1752 15.2635-42.3108 11.485-18.8234-4.408-34.4678-13.9582-48.6893-26.6907-12.4683-11.163-25.5625-21.6245-38.2986-32.4914-10.1337-8.6463-20.085-17.5064-30.2246-26.1457-12.491-10.643-25.1287-21.1137-37.6344-31.7397-7.7715-6.6034-15.369-13.4113-23.1284-20.0291-10.6154-9.0537-21.3547-17.9621-31.9584-27.0293-10.1243-8.6574-20.0986-17.4902-30.2201-26.151-10.7266-9.1785-21.5488-18.2453-32.5853-27.6122z" />
    <path d="M360.2945 478.9795c-19.0343-4.5287-31.328-17.376-42.6045-31.689-17.2736-21.9252-31.2114-45.9522-44.6862-70.2812-8.4413-15.2409-16.8506-30.4994-25.799-46.7002 5.5267-.8417 10.3373-1.5548 15.1415-2.3092 31.0915-4.882 59.9162-15.1186 85.0572-34.7667 17.5247-13.6957 31.9249-30.1919 44.5076-48.2732 14.119-20.289 28.2122-40.6533 41.0945-61.7318 17.3998-28.47 33.3445-57.8303 43.1416-89.966 2.8087-9.2128 5.5764-18.9756 5.4158-28.4362-.2728-16.0726-5.963-30.744-18.2977-42.0276-.7264-.6645-1.7005-1.0582-3.6296-2.2255 7.6536-.1913 19.9557 7.2399 24.3636 13.0617 8.4762 11.1949 11.2307 23.967 12.145 37.695 1.1196 16.8139-2.6078 32.8546-5.8359 49.0635-2.6255 13.1833-4.836 26.4487-7.3201 39.6609-2.9304 15.5855-6.0302 31.1394-8.9227 46.7318-3.1025 16.725-5.9848 33.4907-9.0831 50.2165-2.8888 15.5938-6.0129 31.144-8.8909 46.7398-3.1167 16.8892-6.0011 33.8213-9.1134 50.7115-2.5511 13.845-5.2603 27.6613-7.9524 41.48-1.6052 8.2396-2.714 16.637-5.0938 24.6523-5.642 19.002-14.8338 36.0326-30.1885 49.1372-6.3267 5.3996-13.9791 8.2319-22.0403 9.1172-6.9133.7592-13.9763.1562-21.4087.1392z" />
    <path d="M139.2874 135.0077c-3.5766.3347-6.6705.777-9.78.9742-23.236 1.4732-46.4077 3.4964-68.609 11.1317-19.6463 6.7566-33.1903 19.8804-39.8317 39.7852-.6366 1.908-1.2662 3.8186-2.2846 6.891-3.8988-23.8466 1.9198-35.83 15.2086-48.0189 12.7903-11.7316 28.37-18.6833 44.8147-24.36 37.4033-12.9116 74.4588-26.828 111.7099-40.1849 40.6216-14.5654 81.3146-28.9315 121.956-43.4418 19.3244-6.8995 38.4561-14.3806 57.9659-20.7005 8.7476-2.8337 18.2346-3.3338 27.3405-5.1248 11.43-2.2482 22.575-.4415 33.6582 2.086 16.8512 3.8427 34.3434 24.855 36.7491 41.8387 2.5372 17.9113-2.5028 34.185-9.3079 49.9224-17.3675 40.1645-40.1746 77.3907-63.5242 114.2762-.1694.2676-.502.432-.845.7164-4.5948-5.5317-8.8259-11.4163-13.8551-16.5135-10.0771-10.2134-19.906-20.8554-31.0804-29.7605-17.935-14.2927-38.5602-23.8216-61.0799-28.641-11.316-2.4217-22.7645-4.4633-34.2562-5.7683-16.0104-1.8182-32.0954-3.3426-48.1886-3.9803-22.0753-.8748-44.188-.8006-66.7603-1.1273z" />
  </svg>
);

import { postFragment } from '../fragments';

export const listPosts = /* GraphQL */ `
  query listPosts {
    listPosts {
      ...postFragment
    }
  }
  ${postFragment}
`;

import { userFragment } from '../fragments';

export const updateUserMutation = /* GraphQL */ `
  mutation updateUser($user: UpdateUserInput!) {
    user: updateUser(user: $user) {
      ...userFragment
    }
  }
  ${userFragment}
`;

export const updateUserImageMutation = /* GraphQL */ `
  mutation updateUserImage($input: UpdateUserImageInput!) {
    updateUserImage(input: $input) {
      ...userFragment
    }
  }
  ${userFragment}
`;
import React from 'react';

import dayjs from 'dayjs';

import { ColorModeSelect } from '../ColorModeSelect';
import { LeftColumn, Root } from './FloatingFooter.styles';

export const FloatingFooter = () => (
  <Root>
    <LeftColumn>
      <ColorModeSelect />
    </LeftColumn>
    <div>&#169; {dayjs().year()}</div>
  </Root>
);

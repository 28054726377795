import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export type DialogDataType = {
  dialogTitle: string;
  isDialogOpen: boolean;
  onCloseDialog?: () => void;
  onConfirmDialog?: () => void;
};

export const ConfirmationDialog = ({
  dialogTitle,
  isDialogOpen,
  onCloseDialog,
  onConfirmDialog,
}: DialogDataType) => (
  <Dialog onClose={onCloseDialog} open={isDialogOpen}>
    <DialogTitle>{dialogTitle}</DialogTitle>
    <Button variant="outlined" onClick={onConfirmDialog} color="success">
      Yes
    </Button>
    <Button variant="outlined" onClick={onCloseDialog} color="warning">
      No
    </Button>
  </Dialog>
);

import { useCallback, useEffect, useState } from 'react';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import { API } from 'aws-amplify';

type StateType<T> = {
  data: T | undefined;
  error: boolean;
  errorMessage?: string;
  isLoading: boolean;
};

type VariableType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [varName: string]: any;
};

type Props = {
  query: string;
  variables?: VariableType;
  fetchOnMount?: boolean;
};

type ReturnType<T> = {
  data: T | undefined;
  error: boolean;
  errorMessage?: string;
  isLoading: boolean;
  refetch: (variables?: VariableType) => Promise<T | undefined>;
};

export const useQuery = <T>({
  query,
  variables,
  fetchOnMount = true,
}: Props): ReturnType<T> => {
  const [state, setState] = useState<StateType<T>>({
    data: undefined,
    error: false,
    isLoading: false,
  });

  const fetchApi = useCallback(
    async (innerVariables?: VariableType): Promise<T | undefined> => {
      setState({ data: undefined, error: false, isLoading: true });
      try {
        const data = (await API.graphql({
          query,
          variables: innerVariables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })) as T;
        setState({
          data,
          error: false,
          errorMessage: undefined,
          isLoading: false,
        });
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState({
          data: undefined,
          error: true,
          errorMessage: error?.graphQLErrors?.[0]?.message,
          isLoading: false,
        });
        // eslint-disable-next-line no-console
        console.log('Error fetching data.', {
          query,
          innerVariables,
          error,
        });
      }
      return undefined;
    },
    [query],
  );

  const refetch = async (innerVariables?: VariableType) => {
    const response = await fetchApi(innerVariables);
    return response;
  };

  useEffect(() => {
    if (fetchOnMount) {
      fetchApi(variables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    ...state,
    refetch,
  };
};

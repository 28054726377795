import { styled } from '@mui/system';

type AccordionStylePropType = {
  isCollapsed: boolean;
};

const styledOptions = {
  shouldForwardProp: (props: string) => props !== 'isCollapsed',
};

const ROOT_HEIGHT = {
  LG: 700,
  MD: 480,
  SM: 230,
  XS: 170,
};

export const Root = styled(
  'div',
  styledOptions,
)(({ isCollapsed }: AccordionStylePropType) => ({ theme }) => ({
  display: 'block',
  width: '100%',
  height: 0,
  overflow: 'hidden',
  transition: '0.75s ease height',
  ...(!isCollapsed && {
    marginBottom: 40,
    height: ROOT_HEIGHT.LG,

    [theme.breakpoints.down('md')]: {
      height: ROOT_HEIGHT.MD,
    },

    [theme.breakpoints.down('sm')]: {
      height: ROOT_HEIGHT.SM,
    },

    [theme.breakpoints.down('xs')]: {
      height: ROOT_HEIGHT.XS,
    },
  }),
}));

export const Content = styled(
  'div',
  styledOptions,
)(({ isCollapsed }: AccordionStylePropType) => ({ theme }) => ({
  '&:first-of-type': {
    display: 'flex',
    justifyContent: 'center',

    position: 'relative',
    width: '100%',
    height: ROOT_HEIGHT.LG,
    borderRadius: '12px',
    overflowY: 'scroll',

    [theme.breakpoints.down('md')]: {
      height: ROOT_HEIGHT.MD,
    },

    [theme.breakpoints.down('sm')]: {
      height: ROOT_HEIGHT.SM,
    },

    [theme.breakpoints.down('xs')]: {
      height: ROOT_HEIGHT.XS,
    },

    '& img': {
      transition: '0.75s ease transform',
      transform: isCollapsed ? 'scale3d(1.02, 1.02, 1)' : 'scale3d(1, 1, 1)',
      display: 'flex',
      alignItems: 'flex-start',
      width: '-webkit-fill-available',
      height: 'fit-content',
      borderRadius: '12px',
      marginTop: 16,
    },
  },
}));

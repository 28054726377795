import React from 'react';

import { IconPropsType } from '../';

export const SupergroupLogo = ({ width, fill }: IconPropsType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    width={width || 200}>
    <path
      d="M0 100v100h200V0H0v100zm59.5-63c2.2 1 4.8 2.4 5.7 3.1 1.7 1.3 1.6 1.6-.7 4.6l-2.6 3.2-5.2-2.4c-8.8-4.2-20.5-2.8-22.2 2.6-1.9 5.9 1.7 8.9 13 10.9 13.9 2.5 19.5 6.6 20.3 15 .6 6.1-1 10-5.4 14-7 6.1-21.3 6.8-31.9 1.5-3.2-1.7-6.1-3.5-6.3-4.1-.1-.7.8-2.4 2.1-3.9l2.3-2.8 4.9 2.8c9.1 5.4 23.3 3.4 24.3-3.5 1-6.4-1-7.9-13.9-10.5-13.3-2.7-17.7-5.7-19.3-13.2-1.5-6.6 3-14.9 9.4-17.6 7.4-3 18.4-2.9 25.5.3zm58.7 1.3c5.8 3.4 7.8 7.5 7.8 16 0 5-.5 7.1-2.3 9.7-3.9 5.9-7.8 7.4-19.9 7.9l-10.8.3V92H83V64.2c0-15.3.3-28.2.8-28.6.4-.5 7.3-.6 15.3-.4 13.6.4 14.8.6 19.1 3.1zm47.7-2.4c11.7 2.3 17.5 14.5 12.1 25.6-2.2 4.5-4 6.1-9.3 8l-2.8 1.1 1.8 2.4c1.1 1.4 4.5 6.2 7.7 10.7l5.7 8.3h-11L163 81.5 155.8 71H146v21h-9V35h12.3c6.7.1 14.2.5 16.6.9zM60 109.4c3.6 1.9 11 8.4 11 9.8 0 .4-1.7 1.6-3.8 2.7l-3.8 1.9-3.3-3.4c-9.2-9.3-26.2-5.7-30.3 6.5-3.2 9.6-.2 22.6 6.3 27.2 5 3.5 15.1 3.9 19.8.7 4-2.7 6.7-6.3 7.5-10.1l.6-2.7H47v-8.1l13.3.3 13.2.3-.3 5.9c-.5 10.8-6.2 19.5-15.1 23.4-5.7 2.4-17.5 2.1-23.9-.7-10.7-4.8-15.8-14.7-15-29.5.7-13.2 6.1-21 17.5-25.2 5.2-1.9 18.6-1.3 23.3 1zm58 .9c11.4 6 11.3 24.4 0 30.4-2.4 1.3-4.5 2.5-4.7 2.6-.1.2 2.9 4.8 6.7 10.2 3.9 5.4 7 10.2 7 10.7 0 .4-2.2.8-4.9.8h-4.9l-7.7-11-7.6-11H93v22h-9v-57h14.8c13.6 0 15.3.2 19.2 2.3zm54.4.6c7.3 4.6 9.7 15.5 5.4 24.1-3.5 6.8-7.1 8.4-20.3 9l-11 .5-.3 10.2-.3 10.3H137v-57.2l15.8.4c14.5.3 16 .5 19.6 2.7z"
      fill={fill || 'currentColor'}
    />
    <path
      d="M93 54.1v10.2l8.8-.5c4.8-.3 9.8-1.1 11-1.9 5-3.1 5.3-12.1.5-15.9-2.1-1.7-4-2-11.4-2H93v10.1zM146.9 44.1c-.6.3-.9 4.6-.7 9.5l.3 8.9h9.3c9 0 9.5-.1 11.8-2.8 3.3-3.9 3.3-9.9-.1-13.2-1.3-1.4-3.2-2.5-4.2-2.5-1-.1-4.9-.2-8.6-.3-3.8-.1-7.3.1-7.8.4zM93 125.5v9.5h9.1c8.7 0 9.2-.1 12-2.9 2.1-2.2 2.9-3.9 2.9-6.6 0-2.7-.8-4.4-2.9-6.6-2.8-2.8-3.3-2.9-12-2.9H93v9.5zM146.8 116.6c-.5.4-.8 4.9-.8 10.1v9.3h8.9c8.2 0 9.2-.2 12-2.6 2.6-2.2 3.1-3.3 3.1-6.9 0-3.2-.7-5.2-2.4-7.2-2.3-2.6-3-2.8-11.3-3.1-4.8-.2-9.1 0-9.5.4z"
      fill={fill || 'currentColor'}
    />
  </svg>
);

import { styled } from '@mui/system';

export const Root = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '11em 0',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '100px',
    paddingBottom: '80px',
  },
}));

export const Title = styled('h3')({
  margin: 0,
});

export const Headline = styled('h1')(({ theme }) => ({
  marginTop: 42,
  marginBottom: 28,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
}));

export const Tagline = styled('h2')(({ theme }) => ({
  marginTop: 0,
  marginBottom: 28,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

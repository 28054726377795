import React from 'react';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Button from '@mui/material/Button';

import { Link } from '../../components/Link';
import { PageMetaData, PageName } from '../../enums';

type Props = {
  pageTitle?: string;
};

export const Admin = ({ pageTitle }: Props) => {
  const { signOut, user } = useAuthenticator((context) => [context.user]);
  return (
    <div>
      {user ? (
        <>
          {pageTitle && <h1>{pageTitle}</h1>}
          <h2>{PageMetaData[PageName.ADMIN].pageTitle}</h2>
          <div>{user.username}</div>
          <div>{user?.attributes?.email}</div>
          <div>
            <Link href={PageMetaData[PageName.ADMIN_POST_MANAGER].path}>
              {PageMetaData[PageName.ADMIN_POST_MANAGER].pageTitle}
            </Link>
          </div>
          <div>
            <Link href={PageMetaData[PageName.ADMIN_PROFILE_MANAGER].path}>
              {PageMetaData[PageName.ADMIN_PROFILE_MANAGER].pageTitle}
            </Link>
          </div>
          <Button onClick={signOut} variant="outlined">
            Sign out
          </Button>
        </>
      ) : (
        <Authenticator hideSignUp />
      )}
    </div>
  );
};

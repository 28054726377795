import { ColorToColorNameType, ColorModeToThemePaletteType } from '.';

// * To add a new color, start here. Add name to enum & hex to color const.
export enum ColorName {
  BLACK = 'BLACK',
  WHITE = 'WHITE',
  GREEN_01 = 'GREEN_01',
  GREEN_02 = 'GREEN_02',
  RED_01 = 'RED_01',
  RED_02 = 'RED_02',
}

export const COLOR: ColorToColorNameType = {
  [ColorName.BLACK]: '#191919',
  [ColorName.WHITE]: '#EFEFEC',
  [ColorName.GREEN_01]: '#152A21',
  [ColorName.GREEN_02]: '#E6F3ED',
  [ColorName.RED_01]: '#2E0B04',
  [ColorName.RED_02]: '#E7DDDB',
};

// * To add a new mode, start here. Add name to enum & palette to themePalette const.
export enum ColorMode {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  GREEN = 'GREEN',
  RED = 'RED',
}

export const THEME_PALETTE: ColorModeToThemePaletteType = {
  [ColorMode.LIGHT]: {
    background: COLOR.WHITE,
    text: COLOR.BLACK,
  },
  [ColorMode.DARK]: {
    background: COLOR.BLACK,
    text: COLOR.WHITE,
  },
  [ColorMode.GREEN]: {
    background: COLOR.GREEN_01,
    text: COLOR.GREEN_02,
  },
  [ColorMode.RED]: {
    background: COLOR.RED_01,
    text: COLOR.RED_02,
  },
};

import { useCallback, useState } from 'react';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import { API } from 'aws-amplify';

type StateType<T> = {
  data?: T;
  error: boolean;
  isLoading: boolean;
  errorMessage?: string;
};

type VariableType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [varName: string]: any;
};

type Props = {
  mutation: string;
};

type ReturnType = {
  error: boolean;
  errorMessage?: string;
  isLoading: boolean;
  runMutation: <T>(variables?: VariableType) => Promise<T | undefined>;
};

export const useMutation = <T>({ mutation }: Props): ReturnType => {
  const [state, setState] = useState<StateType<T>>({
    error: false,
    isLoading: false,
  });

  const runMutation = useCallback(
    async <T>(variables?: VariableType): Promise<T | undefined> => {
      setState({ error: false, isLoading: true });
      try {
        const data = (await API.graphql({
          query: mutation,
          variables,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })) as T;
        setState({ isLoading: false, error: false, errorMessage: undefined });
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState({
          isLoading: false,
          error: true,
          errorMessage: error?.errors?.[0]?.message,
        });
        // eslint-disable-next-line no-console
        console.log('Error running mutation.', {
          mutation,
          variables,
          error,
        });
      }
      return undefined;
    },
    [mutation],
  );

  return {
    ...state,
    runMutation,
  };
};

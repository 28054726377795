import { PageMetaData, PageName } from '../enums';

type ReturnType = {
  pageTitle?: string;
  setPageMetaTitle: () => void;
};

export const usePageMetaData = (): ReturnType => {
  const { pathname } = window.location;

  const removeTrailingSlashFromURLPath = (urlPath: string): string => {
    return urlPath.slice(-1) === '/' ? urlPath.slice(0, -1) : urlPath;
  };
  const formatURLPath = ({ urlPath }: { urlPath: string }): string => {
    const decodedURLPath = decodeURI(urlPath);
    const trimmedPathName =
      decodedURLPath !== PageMetaData[PageName.HOME].path
        ? removeTrailingSlashFromURLPath(decodedURLPath)
        : PageMetaData[PageName.HOME].path;
    return trimmedPathName;
  };
  const formattedURLPath = formatURLPath({ urlPath: pathname });
  const pageData = Object.values(PageMetaData)?.find(
    (page) => page?.path === formattedURLPath,
  );

  const getMetaTitle = ({
    pageName,
  }: {
    pageName?: PageName | null;
  }): string | null | undefined => {
    if (pageName == null) {
      return null;
    }
    switch (pageName) {
      case PageName.ADMIN:
      case PageName.ADMIN_POST_MANAGER:
      case PageName.ADMIN_PROFILE_MANAGER:
      case PageName.HOME:
      case PageName.WRITING:
        return pageData?.metaTitle;
      default: {
        const exhaustiveCheck: never = pageName;
        throw new Error(`Unhandled pageName type case: ${exhaustiveCheck}`);
      }
    }
  };

  const setPageMetaTitle = (): void => {
    document.title =
      getMetaTitle({ pageName: pageData?.pageName }) ??
      PageMetaData.HOME.metaTitle;
  };

  return {
    pageTitle: pageData?.pageTitle ?? '',
    setPageMetaTitle,
  };
};

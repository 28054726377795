import Container from '@mui/material/Container';
import { styled, Theme } from '@mui/system';

type PageContentStylePropsType = {
  isVisuallyCentered: boolean;
  theme?: Theme;
};

const styledOptions = {
  shouldForwardProp: (props: string) => props !== 'isVisuallyCentered',
};

const HEADER_OFFSET_SCREEN_LARGE = '64px';
const HEADER_OFFSET_SCREEN_SMALL = '56px';

export const StyledContainer = styled(
  Container,
  styledOptions,
)(({ isVisuallyCentered, theme }: PageContentStylePropsType) => ({
  ...(isVisuallyCentered && { marginTop: `-${HEADER_OFFSET_SCREEN_LARGE}` }),
  paddingTop: `${HEADER_OFFSET_SCREEN_LARGE}`,
  [theme?.breakpoints.down('sm') as string]: {
    ...(isVisuallyCentered && { marginTop: `-${HEADER_OFFSET_SCREEN_SMALL}` }),
    paddingTop: `${HEADER_OFFSET_SCREEN_SMALL}`,
  },
}));

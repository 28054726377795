import { styled } from '@mui/system';

export const Root = styled('section')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

export const CampaignImage = styled('div')(({ theme }) => ({
  width: '32vw',
  height: '43vw',
  maxHeight: '550px',
  maxWidth: '400px',
  [theme.breakpoints.down('sm')]: {
    width: '82vw',
    height: '113vw',
  },
  '& img': {
    width: '100%',
  },
}));

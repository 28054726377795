import { postFragment, userFragment } from '../fragments';

export const getPageContent = /* GraphQL */ `
  query getPageContent($userId: ID!) {
    user: getUserById(userId: $userId) {
      ...userFragment
    }
    postList: listPosts {
      ...postFragment
    }
  }
  ${postFragment}
  ${userFragment}
`;

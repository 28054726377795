import { postFragment } from '../fragments';

export const createPostMutation = /* GraphQL */ `
  mutation createPost($post: PostInput!) {
    post: createPost(post: $post) {
      ...postFragment
    }
  }
  ${postFragment}
`;

export const deletePostMutation = /* GraphQL */ `
  mutation deletePost($postId: ID!) {
    post: deletePost(postId: $postId)
  }
`;

export const updatePostMutation = /* GraphQL */ `
  mutation updatePost($post: UpdatePostInput!) {
    post: updatePost(post: $post) {
      ...postFragment
    }
  }
  ${postFragment}
`;

import { useCallback, useState } from 'react';

type ReturnType = {
  value: boolean;
  set: (newValue: boolean | (() => boolean)) => void;
  setFalse: () => void;
  setTrue: () => void;
  toggle: () => void;
};

export const useBoolean = (initialState: boolean): ReturnType => {
  const [value, setValue] = useState(initialState);
  return {
    value,
    set: setValue,
    setFalse: useCallback(() => setValue(false), []),
    setTrue: useCallback(() => setValue(true), []),
    toggle: useCallback(() => setValue((v: boolean) => !v), []),
  };
};

import React from 'react';

import { IconPropsType } from '../';

export const SteelHouseLogo = ({ width, fill }: IconPropsType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    width={width || 17}>
    <path
      d="M0 0v17h16.5556V0H0zm14.6872 15.1325H1.8675v-1.9156h11.1713V7.5656H5.4936V5.6502h9.1936v9.4823zm0-11.3501H3.6268V9.433h7.5445v1.9163H1.8675V1.8672h12.8197v1.9152z"
      fill={fill || 'currentColor'}
    />
  </svg>
);

import React from 'react';

import Button from '@mui/material/Button';

import { SectionName } from '../../../enums';
import { IntroType } from '../../../types';
import { Headline, Root, Tagline, Title } from './Intro.styles';

type Props = {
  data?: IntroType;
  scrollToSection: ({ section }: { section: SectionName }) => void;
};

export const Intro = ({ data, scrollToSection }: Props) => {
  const { title, headline, tagline, experienceSectionCallToAction } =
    data ?? {};
  return (
    <Root>
      {title && <Title>{title}</Title>}
      <Headline>{headline}</Headline>
      {tagline && <Tagline>{tagline}</Tagline>}
      <Button
        onClick={() => scrollToSection({ section: SectionName.EXPERIENCE })}
        variant="outlined">
        {experienceSectionCallToAction}
      </Button>
    </Root>
  );
};

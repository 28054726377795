import React from 'react';

import { Route } from '../../components';
import { SectionName, PageMetaData, PageName } from '../../enums';
import {
  Admin,
  Home,
  NotFound,
  PostManager,
  ProfileManager,
  Writing,
} from '../../pages';
import {
  ActionHandlerInputType,
  PageContentDataType,
  SectionKeyToHTMLDivElementRefType,
  SelectedRouteNavigationDataType,
} from '../../types';
import { StyledContainer } from './PageContent.styles';

type Props = {
  data?: PageContentDataType;
  onUpdate: (variables: ActionHandlerInputType) => void;
  pageTitle?: string;
  scrollToSection: ({ section }: { section: SectionName }) => void;
  sectionKeyToHTMLDivElementRef: SectionKeyToHTMLDivElementRefType;
  selectedRouteNavigationData: SelectedRouteNavigationDataType;
};

const PAGE_DATA_PATHS = Object.values(PageMetaData).reduce((pages, page) => {
  pages.push(...[page.path, ...(page?.sections ? page.sections : [])]);
  return pages;
}, [] as string[]);

export const PageContent = ({
  data,
  onUpdate,
  pageTitle,
  scrollToSection,
  sectionKeyToHTMLDivElementRef,
  selectedRouteNavigationData,
}: Props) => (
  <StyledContainer
    isVisuallyCentered={
      selectedRouteNavigationData.path === PageMetaData[PageName.HOME].path
    }>
    <Route path={PageMetaData[PageName.ADMIN].path}>
      <Admin pageTitle={pageTitle} />
    </Route>
    <Route path={PageMetaData[PageName.ADMIN_POST_MANAGER].path} isProtected>
      <PostManager
        data={data?.[PageName.ADMIN_POST_MANAGER]}
        onUpdate={onUpdate}
        pageTitle={pageTitle}
      />
    </Route>
    <Route path={PageMetaData[PageName.ADMIN_PROFILE_MANAGER].path} isProtected>
      <ProfileManager
        data={data?.[PageName.ADMIN_PROFILE_MANAGER]}
        onUpdate={onUpdate}
        pageTitle={pageTitle}
      />
    </Route>
    <Route path={PageMetaData[PageName.HOME].path}>
      <Home
        data={data?.[PageName.HOME]}
        scrollToSection={scrollToSection}
        sectionKeyToHTMLDivElementRef={sectionKeyToHTMLDivElementRef}
      />
    </Route>
    <Route path={PageMetaData[PageName.WRITING].path}>
      <Writing data={data?.[PageName.WRITING]} pageTitle={pageTitle} />
    </Route>
    {!PAGE_DATA_PATHS.includes(selectedRouteNavigationData.path) && (
      <NotFound />
    )}
  </StyledContainer>
);

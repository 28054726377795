/* eslint-disable security/detect-object-injection */
import { alpha } from '@mui/material';
import { styled, Theme } from '@mui/system';

import { ColorMode, THEME_PALETTE } from '../../theme';

type ColorModeSelectStylePropsType = {
  colorMode: ColorMode;
  theme?: Theme;
};

type ThemePaletteIconListItemPropsType = Omit<
  ColorModeSelectStylePropsType,
  'colorMode'
> & {
  isDisabled: boolean;
};

const styledOptions = {
  shouldForwardProp: (props: string) =>
    props !== 'colorMode' && props !== 'isDisabled',
};

const transitionDurationAndTiming = {
  transitionDuration: '0.5s',
  transitionTimingFunction: 'ease',
};

export const Root = styled(
  'div',
  styledOptions,
)(({ colorMode, theme }: ColorModeSelectStylePropsType) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 500,
  width: 145,
  overflow: 'hidden',
  cursor: 'pointer',
  padding: theme?.spacing(1),
  transitionProperty: 'background-color, width',
  ...transitionDurationAndTiming,
  'ul > li > i': {
    opacity: 0,
    transform: 'rotate(112.5deg)',
    transitionProperty: 'opacity, transform, border-color',
    ...transitionDurationAndTiming,

    // Icon hover transitions
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'gray',
    '&:hover': {
      borderColor: 'white',
    },
  },
  // Component root hover transitions
  '&:hover': {
    backgroundColor: alpha(THEME_PALETTE[colorMode].text, 0.1),
    width: 300,
    'ul > li > i': {
      opacity: 1,
      transform: 'rotate(180deg)',
    },
  },
}));

export const Label = styled('div')({
  display: 'grid',
  gridTemplateColumns: '30px 105px',
  alignItems: 'center',
});

export const Text = styled('span')({
  paddingTop: 2,
});

export const ColorModeIndicator = styled(
  'i',
  styledOptions,
)(({ colorMode }: ColorModeSelectStylePropsType) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: THEME_PALETTE[colorMode].text,
  margin: '0 auto',
}));

export const ThemePaletteIconList = styled('ul')({
  display: 'flex',
  height: '100%',
  listStyle: 'none',
});

export const ThemePaletteIconListItem = styled(
  'li',
  styledOptions,
)(({ isDisabled }: ThemePaletteIconListItemPropsType) => ({
  display: 'inline-block',
  ...(isDisabled && {
    opacity: 0.1,
  }),
}));

export const ThemePaletteIcon = styled(
  'i',
  styledOptions,
)(({ colorMode, theme }: ColorModeSelectStylePropsType) => ({
  position: 'relative',
  display: 'block',
  backgroundColor: THEME_PALETTE[colorMode].text,
  width: 20,
  height: 20,
  borderRadius: '50%',
  marginLeft: theme?.spacing(2),
}));

export const ThemePaletteIconBackgroundColor = styled(
  'i',
  styledOptions,
)(({ colorMode }: ColorModeSelectStylePropsType) => ({
  position: 'absolute',
  display: 'block',
  backgroundColor: THEME_PALETTE[colorMode].background,
  top: 2,
  left: 4,
  height: 8,
  width: 16,
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  transform: 'rotate(45deg)',
}));

import React, { MouseEvent, ReactNode } from 'react';

import { useRouteData } from '../../hooks';
import { Root } from './Link.styles';

type Props = {
  children: ReactNode;
  href: string;
  isUnderlined?: boolean;
};

export const Link = ({ children, href }: Props) => {
  const { redirect } = useRouteData({});
  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();
    redirect({ href });
  };
  return (
    <Root href={href} onClick={onClick}>
      {children}
    </Root>
  );
};

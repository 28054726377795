import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';

type ExperienceCardStylePropType = {
  isCollapsed: boolean;
};

const styledOptions = {
  shouldForwardProp: (props: string) => props !== 'isCollapsed',
};

export const Root = styled('section')({
  display: 'flex',
  flexDirection: 'column',
});

export const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '15% 1fr 5%',
  padding: '40px 30px',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '25% 1fr 1%',
    padding: '30px 20px',
  },
}));

export const LeftColumn = styled('div')({
  display: 'flex',
  alignItems: 'start',
});

export const RightColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm') as string]: {
    display: 'none',
  },
}));

export const ToggleAccordionIcon = styled(
  AddIcon,
  styledOptions,
)(({ isCollapsed }: ExperienceCardStylePropType) => ({
  transform: isCollapsed ? 'rotate(0)' : 'rotate(45deg)',
  transition: '0.3s ease transform',
}));

export const Title = styled('h2')(({ theme }) => ({
  fontSize: '2.6em',
  fontWeight: 600,
  marginTop: 0,
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3em',
  },
}));

export const CompanyName = styled('h3')(({ theme }) => ({
  fontSize: '1.6em',
  marginTop: 0,
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2em',
  },
}));

export const Dates = styled('h4')({
  fontSize: '1em',
  marginTop: 0,
  marginBottom: 10,
});

export const SkillList = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm') as string]: {
    display: 'none',
  },
}));

export const Technologies = styled(
  'h4',
  styledOptions,
)(({ isCollapsed }: ExperienceCardStylePropType) => ({
  ...(isCollapsed && {
    width: 450,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  display: 'inline-block',
}));

/* eslint-disable security/detect-object-injection */
import { alpha } from '@mui/material';
import { styled, keyframes } from '@mui/system';

import { ColorMode, THEME_PALETTE } from '../../theme';

type ColorModeProps = {
  colorMode: ColorMode;
};

type LetterProps = {
  animationDelay: string;
};

const styledOptions = {
  shouldForwardProp: (props: string) =>
    props !== 'animationDelay' && props !== 'colorMode',
};

export const Root = styled(
  'div',
  styledOptions,
)(({ colorMode }: ColorModeProps) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  backgroundColor: THEME_PALETTE[colorMode].background,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
}));

const slideOut = keyframes`
  40%, 60% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const Heading = styled(
  'h1',
  styledOptions,
)(({ colorMode }: ColorModeProps) => ({
  overflow: 'hidden',
  height: '100px',
  lineHeight: '100px',
  fontWeight: 200,
  borderTop: `1px solid ${alpha(THEME_PALETTE[colorMode].text, 0.5)}`,
  borderBottom: `1px solid ${alpha(THEME_PALETTE[colorMode].text, 0.5)}`,
}));

export const Letter = styled(
  'span',
  styledOptions,
)(({ animationDelay }: LetterProps) => ({
  display: 'inline-block',
  opacity: 0,
  transform: 'translateY(100%)',
  animation: `${slideOut} 3s ease infinite ${animationDelay}`,
}));

import React from 'react';

import ReactDOM from 'react-dom/client';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';

// Supports weights 100-900
import '@fontsource-variable/montserrat';

import '@fontsource/battambang/300.css';
import '@fontsource/battambang/400.css';
import '@fontsource/battambang/700.css';
import '@fontsource/battambang/900.css';

import 'react-markdown-editor-lite/lib/index.css';

import './global.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

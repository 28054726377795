import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useGlobalComponentData,
  usePageMetaData,
  usePostData,
  useQuery,
  useRouteData,
  useUserData,
} from '.';
import { AlertBarDataType, DialogDataType } from '../components';
import { SectionName, PageName } from '../enums';
import { getPageContent } from '../graphql/queries';
import {
  ActionHandlerInputType,
  GlobalComponentDataType,
  PostActionHandlerInputType,
  PageContentDataType,
  PageContentDataResponseType,
  RedirectProps,
  SectionKeyToHTMLDivElementRefType,
  SelectedRouteNavigationDataType,
  UserActionHandlerInputType,
} from '../types';

const HOME_PAGE_USER_ID = process.env.REACT_APP_HOME_PAGE_USER_ID || '';

type ReturnType = {
  data?: PageContentDataType;
  dialogData: DialogDataType | null;
  globalComponentData?: GlobalComponentDataType;
  onUpdate: (variables: ActionHandlerInputType) => void;
  pageTitle?: string;
  redirect: (params: RedirectProps) => void;
  scrollToSection: ({ section }: { section: SectionName }) => void;
  sectionKeyToHTMLDivElementRef: SectionKeyToHTMLDivElementRefType;
  selectedRouteNavigationData: SelectedRouteNavigationDataType;
  setPageMetaTitle: () => void;
  setSnackbarData: (variables: AlertBarDataType | null) => void;
  shouldDisplayPageLoader: boolean;
  snackbarData: AlertBarDataType | null;
};

export const usePageContentData = (): ReturnType => {
  const [dialogData, setDialogData] = useState<DialogDataType | null>(null);
  const [snackbarData, setSnackbarData] = useState<AlertBarDataType | null>(
    null,
  );
  const [shouldDisplayPageLoader, setShouldDisplayPageLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setShouldDisplayPageLoader(false), 3500);
  }, []);

  const { pageTitle, setPageMetaTitle } = usePageMetaData();
  const { data: globalComponentData } = useGlobalComponentData();

  const { data: pageContentData, error: pageContentDataHasError } = useQuery<
    PageContentDataResponseType | undefined
  >({
    query: getPageContent,
    variables: { userId: HOME_PAGE_USER_ID },
  });

  const isDataFetching = !pageContentData;
  const isPageLoading = shouldDisplayPageLoader || isDataFetching;

  const {
    redirect,
    scrollToSection,
    sectionKeyToHTMLDivElementRef,
    selectedRouteNavigationData,
  } = useRouteData({ isPageLoading });

  const { data: userSectionData, dispatchAction: dispatchUserAction } =
    useUserData({
      data: pageContentData?.data?.user || undefined,
      isDataFetching,
      setDialogData,
      setSnackbarData,
    });

  const { data: postSectionData, dispatchAction: dispatchPostAction } =
    usePostData({
      data: pageContentData?.data?.postList || [],
      isDataFetching,
      setDialogData,
      setSnackbarData,
    });

  const sectionData: PageContentDataType = useMemo(
    () => ({
      [PageName.ADMIN_POST_MANAGER]: postSectionData,
      [PageName.ADMIN_PROFILE_MANAGER]: userSectionData,
      [PageName.HOME]: userSectionData,
      [PageName.WRITING]: {
        postList: postSectionData?.postList ?? [],
        isLoading: isDataFetching,
      },
    }),
    [isDataFetching, postSectionData, userSectionData],
  );

  const onUpdate = useCallback(
    ({ action, data, page, shouldConfirm }: ActionHandlerInputType) => {
      switch (page) {
        case PageName.ADMIN_POST_MANAGER:
          return dispatchPostAction({
            action,
            data,
            shouldConfirm,
          } as PostActionHandlerInputType);
        case PageName.ADMIN_PROFILE_MANAGER:
          return dispatchUserAction({
            action,
            data,
            shouldConfirm,
          } as UserActionHandlerInputType);
        case PageName.ADMIN:
        case PageName.HOME:
        case PageName.WRITING:
          return;
        default: {
          const exhaustiveCase: never = page;
          throw new Error(`Unhandled PageName case: ${exhaustiveCase}`);
        }
      }
    },
    [dispatchPostAction, dispatchUserAction],
  );

  useEffect(() => {
    if (pageContentDataHasError) {
      setSnackbarData({
        severity: 'error',
        text: 'Error loading page content',
      });
    }
  }, [pageContentDataHasError, setSnackbarData]);

  return {
    data: sectionData,
    dialogData,
    globalComponentData,
    onUpdate,
    pageTitle,
    redirect,
    scrollToSection,
    sectionKeyToHTMLDivElementRef,
    selectedRouteNavigationData,
    setPageMetaTitle,
    setSnackbarData,
    shouldDisplayPageLoader,
    snackbarData,
  };
};

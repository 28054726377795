/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';

import { createTheme, ThemeProvider } from '@mui/material';

import { ColorMode, useThemeOptions } from '.';

type ThemeContextProviderType = {
  children?: ReactNode;
  setColorMode?: ({ mode }: { mode: ColorMode }) => void;
  colorMode?: ColorMode;
};

export const ThemeContext = createContext<ThemeContextProviderType>({
  colorMode: ColorMode.LIGHT,
  setColorMode: () => {},
});

export const ThemeContextProvider: FC<ThemeContextProviderType> = ({
  children,
}) => {
  const localStorageKey = 'colorMode';
  const defaultColorMode =
    (localStorage.getItem(localStorageKey) as ColorMode) || ColorMode.LIGHT;
  const { getDesignTokens } = useThemeOptions();
  const [mode, setMode] = useState(defaultColorMode);
  const themeContextValue = useMemo(
    () => ({
      colorMode: mode,
      setColorMode: ({ mode }: { mode: ColorMode }) => {
        localStorage.setItem(localStorageKey, mode);
        setMode(mode);
      },
    }),
    [mode],
  );

  const theme = useMemo(
    () => createTheme(getDesignTokens({ mode })),
    [getDesignTokens, mode],
  );

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

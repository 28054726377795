import { styled } from '@mui/system';

export const Root = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '38% auto',
  padding: '120px 0',
  fontFamily: 'Battambang, serif',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '25vw auto',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'unset',
  },
}));

export const Headline = styled('h1')(({ theme }) => ({
  fontSize: '2em',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8em',
  },
}));

export const Subheading = styled('h2')(({ theme }) => ({
  fontSize: '1.6em',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4em',
  },
}));

export const SkillGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 2fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    'LeftGridColumn CenterGridColumn RightGridColumn'
  `,
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: '1fr 2fr',
    gridTemplateAreas: `
      'LeftGridColumn CenterGridColumn'
      'LeftGridColumn RightGridColumn'
    `,
  },
}));

export const LeftGridColumn = styled('div')(({ theme }) => ({
  gridArea: 'LeftGridColumn',
  fontSize: '1.5em',
  paddingTop: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    gridRow: 1 / 2,
    paddingTop: 0,
    fontSize: '1em',
  },
}));

export const CenterGridColumn = styled('div')({
  gridArea: 'CenterGridColumn',
});

export const RightGridColumn = styled('div')({
  gridArea: 'RightGridColumn',
});

export const SkillList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  fontSize: '1.8em',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1em',
  },
}));

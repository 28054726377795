import { GlobalComponent } from '../enums';
import { GlobalComponentDataType } from '../types';

type ReturnType = {
  data?: GlobalComponentDataType;
};

const DEFAULT_GLOBAL_COMPONENT_DATA: GlobalComponentDataType = {
  [GlobalComponent.FOOTER]: {
    emailAddress: 'mikeg610@gmail.com',
    emailSubjectLine: 'Contact via website',
    emailCallToAction: 'Send an email',
    // eslint-disable-next-line quotes
    headline: "I'm always happy to talk.",
    isOpenToWork: false,
    links: [
      {
        href: 'https://www.linkedin.com/in/mikegutierrez/',
        title: 'LINKEDIN',
        order: 1,
      },
      {
        href: 'https://www.instagram.com/mikegsound/',
        title: 'INSTAGRAM',
        order: 2,
      },
    ],
  },
  [GlobalComponent.NAVIGATION]: {
    isOpenToWork: true,
    navigationTitle: 'MIKEG.',
  },
};

export const useGlobalComponentData = (): ReturnType => {
  return {
    data: DEFAULT_GLOBAL_COMPONENT_DATA,
  };
};

import React, { forwardRef } from 'react';

import { RefElement, Root } from './Anchor.styles';

type Props = {
  verticalOffset?: string | number;
};

export const Anchor = forwardRef<HTMLDivElement, Props>(
  ({ verticalOffset }, ref) => (
    <Root>
      <RefElement ref={ref} style={{ top: verticalOffset }} />
    </Root>
  ),
);

Anchor.displayName = 'Anchor';

export const userFragment = /* GraphQL */ `
  fragment userFragment on User {
    id
    firstName
    lastName
    biography
    location
    image
  }
`;

import { useCallback, useState } from 'react';

import { Storage } from 'aws-amplify';

import { invalidateFile } from '../graphql/queries';
import { useQuery } from '../hooks';
import { FileInputType, FileReturnType } from '../types';

type ReturnType = {
  errorMessage: string;
  getImageUrl: ({ fileName }: { fileName: string }) => string;
  invalidateFileCache: ({ fileName }: { fileName: string }) => Promise<void>;
  isLoading: boolean;
  removeFile: ({ fileName }: { fileName: string }) => Promise<FileReturnType>;
  uploadFile: ({ file }: FileInputType) => Promise<FileReturnType>;
};

export const useStorage = (): ReturnType => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { refetch: runInvalidateFile } = useQuery({
    query: invalidateFile,
    fetchOnMount: false,
  });

  const getImageUrl = useCallback(
    ({ fileName }: { fileName: string }): string => {
      return `https://d2pviryyz9qcvf.cloudfront.net/${fileName}`;
    },
    [],
  );

  const invalidateFileCache = useCallback(
    async ({ fileName }: { fileName: string }) => {
      await runInvalidateFile({ fileName });
    },
    [runInvalidateFile],
  );

  const removeFile = useCallback(
    async ({ fileName }: { fileName: string }): Promise<FileReturnType> => {
      try {
        setIsLoading(true);
        await Storage.remove(fileName, {
          customPrefix: {
            public: '',
          },
        });
        await invalidateFileCache({ fileName });
        setIsLoading(false);
        return { success: true };
      } catch (error) {
        setErrorMessage('Error removing file');
        setIsLoading(false);
        return { success: false };
      }
    },
    [invalidateFileCache],
  );

  const uploadFile = useCallback(
    async ({ file }: FileInputType): Promise<FileReturnType> => {
      const fileName = file?.name;
      try {
        setIsLoading(true);
        const response = (await Storage.put(fileName, file, {
          customPrefix: {
            public: '',
          },
          contentType: file?.type,
          contentDisposition: 'inline',
        })) as { key: string };
        if (response?.key) {
          setIsLoading(false);
          return { success: true, fileName };
        }
      } catch (error) {
        setErrorMessage('Error uploading file');
      }
      setIsLoading(false);
      return { success: false, fileName };
    },
    [setErrorMessage, setIsLoading],
  );

  return {
    errorMessage,
    getImageUrl,
    invalidateFileCache,
    isLoading,
    removeFile,
    uploadFile,
  };
};

export enum PageName {
  ADMIN = 'ADMIN',
  ADMIN_POST_MANAGER = 'ADMIN_POST_MANAGER',
  ADMIN_PROFILE_MANAGER = 'ADMIN_PROFILE_MANAGER',
  HOME = 'HOME',
  WRITING = 'WRITING',
}

export enum SectionName {
  ABOUT = 'ABOUT',
  CONTACT = 'CONTACT',
  EXPERIENCE = 'EXPERIENCE',
}

export type LocationPathType = `/${string}`;

export type PageDataType = {
  pageName: PageName;
  path: LocationPathType;
  metaTitle: string;
  pageTitle?: string;
  sections?: LocationPathType[];
};

export type PageNameToPageDataMapType = {
  [pageName in PageName]: PageDataType;
};

export const PageMetaData: PageNameToPageDataMapType = {
  [PageName.ADMIN]: {
    pageName: PageName.ADMIN,
    path: '/admin',
    metaTitle: 'Admin Panel',
    pageTitle: 'Admin Panel',
  },
  [PageName.ADMIN_POST_MANAGER]: {
    pageName: PageName.ADMIN_POST_MANAGER,
    path: '/admin/posts',
    metaTitle: 'Admin Panel | Post Manager',
    pageTitle: 'Post Manager',
  },
  [PageName.ADMIN_PROFILE_MANAGER]: {
    pageName: PageName.ADMIN_PROFILE_MANAGER,
    path: '/admin/profile',
    metaTitle: 'Admin Panel | Profile Manager',
    pageTitle: 'Profile Manager',
  },
  [PageName.HOME]: {
    pageName: PageName.HOME,
    path: '/',
    metaTitle: 'Mike Gutierrez | Software Engineer',
    pageTitle: 'Home',
    sections: ['/about', '/experience', '/contact'],
  },
  [PageName.WRITING]: {
    pageName: PageName.WRITING,
    path: '/writing',
    metaTitle: 'Mike Gutierrez | Writing',
    pageTitle: 'Writing',
  },
};

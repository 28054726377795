import React, { forwardRef } from 'react';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';

import {
  Anchor,
  EmploymentStatus,
  Link as InternalLink,
} from '../../components';
import { PageMetaData, PageName } from '../../enums';
import { FooterComponentDataType } from '../../types';
import {
  Content,
  ContentText,
  EmailLink,
  Navigation,
  NavigationLeftColumn,
  NavigationRightColumn,
  Root,
} from './Footer.styles';

type Props = {
  data?: FooterComponentDataType;
};

export const Footer = forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
  const {
    emailAddress,
    emailCallToAction,
    emailSubjectLine,
    headline,
    isOpenToWork,
    links,
  } = data || {};
  return (
    <Root>
      <Anchor ref={ref} verticalOffset={500} />
      <Container>
        <Content>
          <ContentText>
            <h4>{headline}</h4>
            {isOpenToWork && <EmploymentStatus size="large" />}
          </ContentText>
          <EmailLink
            href={`mailto:${emailAddress}?subject=${emailSubjectLine}`}>
            {emailCallToAction}
          </EmailLink>
        </Content>
      </Container>
      <Navigation>
        <NavigationLeftColumn>
          {links
            ?.sort((a, b) => a.order - b.order)
            ?.map((data) => (
              <Button
                key={data.order}
                href={data.href}
                target="_"
                variant="text">
                {data.title}
              </Button>
            ))}
        </NavigationLeftColumn>
        <NavigationRightColumn>
          <InternalLink href={PageMetaData[PageName.ADMIN].path}>
            <IconButton aria-label="admin">
              <AdminPanelSettingsIcon />
            </IconButton>
          </InternalLink>
          <span>&#169; {dayjs().year()}</span>
        </NavigationRightColumn>
      </Navigation>
    </Root>
  );
});

Footer.displayName = 'Footer';

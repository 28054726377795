import React from 'react';

import { SectionName } from '../../enums';
import {
  HomePageDataType,
  SectionKeyToHTMLDivElementRefType,
} from '../../types';
import { About } from './About';
import { Campaign } from './Campaign';
import { Experience } from './Experience';
import { Intro } from './Intro';

type Props = {
  data?: HomePageDataType;
  scrollToSection: ({ section }: { section: SectionName }) => void;
  sectionKeyToHTMLDivElementRef: SectionKeyToHTMLDivElementRefType;
};

export const Home = ({
  data,
  scrollToSection,
  sectionKeyToHTMLDivElementRef,
}: Props) => {
  const { userData } = data ?? {};
  const { about, experience, image, intro } = userData ?? {};
  return (
    <>
      <Campaign image={image} />
      <Intro data={intro} scrollToSection={scrollToSection} />
      <Experience
        ref={sectionKeyToHTMLDivElementRef.EXPERIENCE}
        data={experience}
      />
      <About ref={sectionKeyToHTMLDivElementRef.ABOUT} data={about} />
    </>
  );
};

import { styled } from '@mui/system';

export const Root = styled('footer')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

export const Content = styled('div')(({ theme }) => ({
  padding: '11em 0',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '100px',
    paddingBottom: '80px',
  },
}));

export const ContentText = styled('div')(({ theme }) => ({
  marginBottom: 120,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 50,
  },
  '& h4': {
    fontFamily: 'Montserrat Variable, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 400,
    margin: 0,
  },
}));

export const EmailLink = styled('a')(({ theme }) => ({
  fontFamily: 'Battambang, serif',
  fontSize: '3em',
  textDecoration: 'underline',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.9rem',
  },
}));

export const Navigation = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  textAlign: 'left',
  fontSize: 14,
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    padding: theme.spacing(2),
  },
}));

export const NavigationLeftColumn = styled('div')(({ theme }) => ({
  flexGrow: 1,
  fontWeight: 500,
  '& a': {
    paddingRight: theme.spacing(3),
    '&:first-of-type': {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      paddingRight: theme.spacing(1),
    },
  },
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const NavigationRightColumn = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

import React, { ChangeEvent, MouseEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

import { PageName, UserAction } from '../../enums';
import {
  ActionHandlerInputType,
  AdminProfileManagerPageDataType,
} from '../../types';

type FormData = {
  id: string;
  image: string;
  firstName: string;
  lastName: string;
  location: string;
  biography: string;
};

type Props = {
  data?: AdminProfileManagerPageDataType;
  onUpdate: (variables: ActionHandlerInputType) => void;
  pageTitle?: string;
};

const MAX_FILE_SIZE = 10000000;

export const ProfileManager = ({ data, onUpdate, pageTitle }: Props) => {
  const { userData, isLoading } = data ?? {};
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<FormData>();
  const formHasErrors = Boolean(Object.keys(errors).length);

  const onSubmit = handleSubmit((data) => {
    if (!formHasErrors) {
      const formData = { ...data };
      onUpdate({
        action: UserAction.UPDATE,
        data: formData,
        page: PageName.ADMIN_PROFILE_MANAGER,
        shouldConfirm: true,
      });
    }
  });

  const onUploadImage = (event: ChangeEvent) => {
    if (event) {
      const element = event.currentTarget as HTMLInputElement;
      const file: File | null = element?.files?.[0] ?? null;
      if (!file) {
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setError('image', { message: 'Max file size: 10 MB' });
      } else {
        onUpdate({
          action: UserAction.UPLOAD_IMAGE,
          data: { id: userData?.id ?? '', ...userData, file },
          page: PageName.ADMIN_PROFILE_MANAGER,
          shouldConfirm: true,
        });
      }
    }
  };

  const onDeleteImage = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      onUpdate({
        action: UserAction.REMOVE_IMAGE,
        data: userData,
        page: PageName.ADMIN_PROFILE_MANAGER,
        shouldConfirm: true,
      });
    } catch (error) {
      setError('image', { message: 'Error deleting image' });
      return;
    }
  };

  useEffect(() => {
    if (userData) {
      const formData = { ...userData } || {};
      reset(formData);
    }
  }, [reset, userData]);

  return (
    <>
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: 20 }}>
        <h1>{pageTitle}</h1>
        {isLoading && <CircularProgress size={20} />}
      </div>
      <Box component="form" noValidate autoComplete="off">
        <div style={{ marginBottom: 20 }}>
          {userData?.image ? (
            <>
              <img
                alt="Mike Gutierrez profile"
                src={userData.image}
                height={200}
              />
              <div style={{ marginTop: 20 }}>
                <Button onClick={onDeleteImage} variant="outlined">
                  Delete
                </Button>
              </div>
            </>
          ) : (
            <>
              <Input
                {...register('image')}
                name="image"
                type="file"
                inputProps={{ accept: 'image/jpg' }}
                onChange={onUploadImage}
              />
              {errors?.image && <div>{errors?.image?.message}</div>}
            </>
          )}
        </div>
        <div style={{ display: 'inline-flex', gap: 20, marginBottom: 20 }}>
          <Controller
            name="firstName"
            control={control}
            defaultValue={userData?.firstName ?? ''}
            render={({ field }) => <TextField {...field} />}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue={userData?.lastName ?? ''}
            render={({ field }) => <TextField {...field} />}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <Controller
            name="location"
            control={control}
            defaultValue={userData?.location ?? ''}
            render={({ field }) => <TextField {...field} />}
          />
        </div>
        <div>
          <Controller
            name="biography"
            control={control}
            defaultValue={userData?.biography ?? ''}
            render={({ field }) => <TextField {...field} multiline rows={6} />}
          />
        </div>
        <div style={{ display: 'inline-flex', gap: 20, margin: '20px 0' }}>
          <Button
            disabled={formHasErrors}
            onClick={onSubmit}
            variant="outlined">
            Save
          </Button>
          <Button
            color="info"
            onClick={() => reset(userData)}
            variant="outlined">
            Cancel
          </Button>
        </div>
      </Box>
    </>
  );
};

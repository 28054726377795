import { styled } from '@mui/system';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  margin: `${theme.spacing(3)} 0`,
  padding: `0 ${theme.spacing(3)}`,
  maxWidth: 1200,
  fontSize: 14,
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: theme.spacing(2),
    fontSize: 12,
  },
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const LeftColumn = styled('div')({
  flexGrow: 1,
});

import React from 'react';

import { IconPropsType } from '../';

export const RivianLogo = ({ width, fill }: IconPropsType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1527 1481"
    width={width || 1527}>
    <path
      d="M1513.75 703.89c-43.14 0-143.2.15-203.39.15-21.23 0-27.52-3.71-40.86-18.05l-79.75-85.6c-84.45-91-290.42-292.08-365.69-355.55-28.28-23.81-60.27-23.05-60.73-23.05-.45 0-32.44-.76-60.72 23.05-75.27 63.39-281.24 264.48-365.69 355.55 0 0-66.41 71.27-79.75 85.6-13.34 14.34-19.63 18.05-40.86 18.05-60.27 0-160.25-.15-203.39-.15-10.61 0-16.98-12.74-9.93-20.85 86.34-89.1 553.54-571.05 641.1-640.58C674.56 17.97 713.91 0 763.33 0c49.35 0 88.7 17.97 119.25 42.46 87.55 69.53 554.68 551.48 641.02 640.58 7.05 8.11.76 20.93-9.85 20.85Zm-1501 73.02c43.14 0 143.2-.15 203.4-.15 21.22 0 27.51 3.71 40.85 18.04l79.75 85.61c84.45 90.99 290.42 292.08 365.7 355.54 28.27 23.81 60.26 23.05 60.72 23.05.45 0 32.44.76 60.72-23.05 75.28-63.39 281.24-264.48 365.69-355.54 0 0 66.41-71.28 79.75-85.61 13.34-14.33 19.63-18.04 40.86-18.04 60.27 0 160.26.15 203.39.15 10.61 0 16.98 12.73 9.93 20.85-86.34 89.09-553.46 571.04-641.02 640.57-30.48 24.49-69.82 42.46-119.25 42.46-49.35 0-88.69-17.97-119.24-42.46C556.44 1368.8 89.32 886.85 2.98 797.76c-7.05-8.12-.69-20.85 9.77-20.85ZM726.4 941.83v199.5c0 9.47-9.02 13.64-16.68 6.97-85.81-79.54-277.15-269.18-341.36-342.65-30.62-32.61-27.82-65.67-27.82-65.67s-2.88-33.14 27.82-65.67c64.14-73.47 255.48-263.11 341.36-342.65 7.28-6.75 16.68-1.74 16.68 6.29v200.11c0 18.04-3.79 27.3-19.56 42.01-38.51 35.18-106.35 100.99-131.6 130.49-11.52 13.04-10.76 29.34-10.76 29.34s-.76 16.31 10.76 29.35c25.25 29.49 93.09 95.31 131.6 130.5 15.77 14.63 19.56 23.88 19.56 42.08Zm93.39-41.93c38.52-35.18 106.36-101 131.61-130.5 11.52-13.04 10.76-29.34 10.76-29.34s.76-16.31-10.76-29.35c-25.25-29.49-93.09-95.31-131.61-130.49-15.76-14.71-19.55-23.96-19.55-42.01v-199.5c0-9.47 9.02-13.64 16.68-6.97 85.88 79.54 277.22 269.18 341.35 342.65 30.63 32.53 27.82 65.67 27.82 65.67s2.81 33.06-27.82 65.66c-64.21 73.48-255.54 263.12-341.35 342.66-7.66 6.67-16.68 2.5-16.68-6.98V941.91c.07-18.13 3.79-27.3 19.55-42.01Z"
      fill={fill || 'currentColor'}
    />
  </svg>
);

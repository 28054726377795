import { styled } from '@mui/system';

export const Root = styled('div')({
  position: 'relative',
});

export const RefElement = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
});

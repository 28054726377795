import { ReactElement } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';

import { SectionName, PageMetaData, PageName } from '../../enums';
import { useRouteData } from '../../hooks';

type Props = {
  children: ReactElement;
  isProtected?: boolean;
  path: string;
};

export const Route = ({ children, isProtected = false, path }: Props) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { redirect, selectedRouteNavigationData } = useRouteData({});
  const isHomePageSection = PageMetaData[PageName.HOME].sections?.includes(
    selectedRouteNavigationData.path,
  );
  const checkAuthentication = () => {
    if (isProtected && route !== 'authenticated') {
      redirect({ href: PageMetaData[PageName.HOME].path });
      return null;
    }
    return children;
  };

  if (isHomePageSection) {
    const section = selectedRouteNavigationData.path
      .substring(1)
      .toUpperCase() as SectionName;
    redirect({ href: PageMetaData[PageName.HOME].path, section });
    return null;
  }

  return selectedRouteNavigationData.path === path
    ? checkAuthentication()
    : null;
};

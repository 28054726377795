import React, { ReactElement, useState } from 'react';

import { Button, Divider, IconButton } from '@mui/material';

import { Accordion, Icon, IconPropsType } from '../';
import { CompanyIcon } from '../../enums';
import { ExperienceType } from '../../types';
import {
  Dates,
  CompanyName,
  Container,
  LeftColumn,
  RightColumn,
  Root,
  SkillList,
  Technologies,
  Title,
  ToggleAccordionIcon,
} from './ExperienceCard.styles';

type Props = {
  data: ExperienceType;
};

type GetCompanyIconInputType = IconPropsType & {
  icon: CompanyIcon;
};

const getCompanyIcon = ({
  icon,
  ...props
}: GetCompanyIconInputType): ReactElement => {
  switch (icon) {
    case CompanyIcon.APPLE:
      return <Icon.AppleLogo {...props} />;
    case CompanyIcon.RIVIAN:
      return <Icon.RivianLogo {...props} />;
    case CompanyIcon.STEELHOUSE:
      return <Icon.SteelHouseLogo {...props} />;
    case CompanyIcon.SUPERGROUP:
      return <Icon.SupergroupLogo {...props} />;
    case CompanyIcon.TEKSYSTEMS:
      return <Icon.TEKSystemsLogo {...props} />;
    case CompanyIcon.UPKEEP:
      return <Icon.UpkeepLogo {...props} />;
    default:
      const exhaustiveCase: never = icon;
      throw new Error(`Unhandled CompanyIcon case: ${exhaustiveCase}`);
  }
};

export const ExperienceCard = ({ data }: Props) => {
  const { title, companyName, dates, icon, images, technologies } = data;
  const [isSkillListCollapsed, setIsSkillListCollapsed] = useState(true);
  const [isAccordionCollapsed, setIsAccordionCollapsed] = useState(true);
  return (
    <Root>
      <Divider />
      <Container>
        <LeftColumn>
          {icon && getCompanyIcon({ icon, width: '65%' })}
        </LeftColumn>
        <div>
          <Title>{title}</Title>
          <CompanyName>{companyName}</CompanyName>
          <Dates>{dates}</Dates>
          {technologies && (
            <SkillList>
              <Technologies isCollapsed={isSkillListCollapsed}>
                <strong>Skills: </strong>
                {technologies?.join(' · ')}
              </Technologies>
              {isSkillListCollapsed && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => setIsSkillListCollapsed(false)}>
                  &hellip;see more
                </Button>
              )}
            </SkillList>
          )}
        </div>
        <RightColumn>
          {images?.length && (
            <IconButton
              aria-label="toggle portfolio"
              size="small"
              onClick={() => setIsAccordionCollapsed(!isAccordionCollapsed)}>
              <ToggleAccordionIcon isCollapsed={isAccordionCollapsed} />
            </IconButton>
          )}
        </RightColumn>
      </Container>
      {images?.length && (
        <Accordion isCollapsed={isAccordionCollapsed}>
          <img alt="Portfolio sample" src={images[0]} />
        </Accordion>
      )}
    </Root>
  );
};

import { updateUserMutation } from '../graphql/mutations';
import {
  UpdateUserMutationInputType,
  UpdateUserMutationResponseType,
} from '../types';
import { useMutation } from './useMutation';

type ReturnType = {
  updateUser: (
    variables: UpdateUserMutationInputType,
  ) => Promise<UpdateUserMutationResponseType | undefined>;
  errorMessage?: string;
  hasError: boolean;
  isLoading: boolean;
};

export const useUserMutations = (): ReturnType => {
  const {
    isLoading: updateUserIsLoading,
    error: updateUserHasError,
    errorMessage: updateUserErrorMessage,
    runMutation: updateUser,
  } = useMutation<UpdateUserMutationResponseType>({
    mutation: updateUserMutation,
  });

  return {
    updateUser,
    errorMessage: updateUserErrorMessage,
    hasError: updateUserHasError,
    isLoading: updateUserIsLoading,
  };
};

import React from 'react';

import { FloatingFooter } from '../../../components';
import { CampaignImage, Root } from './Campaign.styles';

type Props = {
  image?: string;
};

export const Campaign = ({ image }: Props) => (
  <Root>
    <CampaignImage>
      {image && <img alt="Mike Gutierrez profile" src={image} />}
    </CampaignImage>
    <FloatingFooter />
  </Root>
);

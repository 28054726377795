export enum PostAction {
  DELETE = 'DELETE',
  DISCARD = 'DISCARD',
  ON_CHANGE = 'ON_CHANGE',
  PUBLISH = 'PUBLISH',
  SELECT = 'SELECT',
  UPDATE = 'UPDATE',
}

export const getPostDialogTitle = ({ action }: { action: PostAction }) => {
  switch (action) {
    case PostAction.DELETE:
      return 'Are you sure you want to delete this post?';
    case PostAction.DISCARD:
      return 'Are you sure you want to discard this post?';
    case PostAction.PUBLISH:
      return 'Are you sure you want to publish this post?';
    case PostAction.UPDATE:
      return 'Are you sure you want to update this post?';
    case PostAction.ON_CHANGE:
    case PostAction.SELECT:
      return '';
    default: {
      const exhaustiveCase: never = action;
      throw new Error(`Unhandled getPostDialogTitle case: ${exhaustiveCase}`);
    }
  }
};
